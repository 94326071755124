jQuery(function () {
	jQuery('.start-video').click(function () {
		var url = jQuery(this).attr('data-video-url');
		jQuery.fancybox({
			beforeLoad: function () {
				this.title = '<a class="fancybox-title" onclick="parent.jQuery.fancybox.close();" title="Close (Esc)">×</a>'
			},
			helpers: {
				title: {
					type: 'outside',
					position: 'top'
				}
			},
			width: "100%",
			maxWidth: 960,
			autoSize: false,
			scrollOutside: true,
			closeBtn: false,
			content: '<div id="video_container">Loading the player ... </div>',
			afterShow: function () {
				jwplayer("video_container").setup({
					file: url,
					autostart: true,
					width: '100%',
					aspectratio: '16:9',
				});
			}
		})
	});
});

const tooltips = {
  init() {
    
    jQuery('[data-tooltip][data-tooltip!=""]').on('mouseenter',function(){
      const el = jQuery(this);
      const tt = jQuery('<div id="tdi--tooltip">' + el.data('tooltip') + '</div>');
      console.log(el.data('tooltip'));

      tt.css('opacity', 0).appendTo(el);

      tooltips.setPosition(el,tt);
      jQuery(window).resize(function(){ tooltips.setPosition(el,tt); });

      el.on('mouseleave', function(){ tooltips.remove(tt); });
      tt.on('click', function(){ tooltips.remove(tt); });
    });
  },
  setPosition(el,tt) {
    tt.removeClass();

    let posLeft = el.offset().left + (el.outerWidth() / 2) - (tt.outerWidth() / 2);
    let posTop  = el.offset().top - tt.outerHeight() - 20;

    if (posLeft < 0) {
      posLeft = el.offset().left + el.outerWidth() / 2 - 20;
      tt.addClass('left');
    }
    else if (posLeft + tt.outerWidth() > jQuery(window).width()) {
      posLeft = el.offset().left - tt.outerWidth() + el.outerWidth() / 2 + 20;
      tt.addClass('right');
    }
    if (posTop < 0) {
      posTop = el.offset().top + el.outerHeight();
      tt.addClass('top');
    }

    tt.css({left: posLeft, top: posTop}).animate({top: '+=10', opacity: 1}, 50);
  },
  remove(tt) {
    tt.animate({top: '-=10', opacity: 0}, 50, function(){ tt.remove(); });
  }
};